import { computed, defineComponent } from 'vue'
import store from './store'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')

export default defineComponent({
  setup() {
    const spinning = computed(() => store.state.showLoading)
    const locale = zhCN
    return () => {
      return (
        <a-config-provider locale={locale}>
          <a-spin
            class="a-spin"
            wrapperClassName="spin"
            spinning={spinning.value}
            size="large"
          >
            <router-view></router-view>
          </a-spin>
        </a-config-provider>
      )
    }
  },
})
