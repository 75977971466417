import { defineComponent, nextTick, ref } from 'vue'
import { EditOutlined } from '@ant-design/icons-vue'
import '../style/index.scss'

export default defineComponent({
  name: 'ParagraphComponent',
  components: {
    EditOutlined,
  },
  emits: ['update:value', 'pressEnter'],
  props: {
    value: {},
    rule: {
      type: Function,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    noContent: {
      type: String,
      default: '未编辑',
    },
  },
  setup(props, { attrs, emit, slots }) {
    const isEdit = ref(false)
    const inputRef = ref()
    const onPressEnter = () => {
      props.rule && props.rule()
      isEdit.value = false
      emit('pressEnter')
    }
    const onChange = (e: any) => {
      emit('update:value', e.target.value)
    }
    const handleClick = () => {
      isEdit.value = true
      nextTick(() => {
        inputRef.value.focus()
      })
    }
    const onBlur = () => {
      onPressEnter()
    }
    return () => {
      const { value, edit, noContent } = props
      return (
        <div class="paragraph-box">
          {isEdit.value ? (
            <a-input
              class="a-input"
              ref={inputRef}
              value={value}
              {...attrs}
              onPressEnter={onPressEnter}
              onChange={onChange}
              onBlur={onBlur}
            />
          ) : (
            <span class="paragraph-val">{value ? value : noContent}</span>
          )}
          {edit && !isEdit.value ? (
            // <edit-outlined class="antd-icon" onClick={handleClick} />
            <span class="antd-icon" onClick={handleClick}>
              修改
            </span>
          ) : (
            ''
          )}
          {slots.default?.()}
        </div>
      )
    }
  },
})
