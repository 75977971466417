import { createApp } from 'vue'
import App from './App'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import { registerElement } from '@/components/index'
import './assets/scss/for.scss'
import './assets/scss/main.scss'
import 'ant-design-vue/dist/antd.css'
import './assets/scss/reset.scss'
// import '@/mock/global'

const app = createApp(App)
app.use(store).use(router).use(Antd).use(registerElement).mount('#app')
