import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'noDataComponent',
  props: {
    tips: {
      type: String,
      default: '暂无数据',
    },
    icon: {
      type: String,
    },
  },
  setup(props) {
    const tips = ref(props.tips)
    const icon = ref(props.icon)
    return () => {
      return (
        <div class="txt-c pt64 pb64">
          {icon.value ? (
            <img src={icon.value} alt="" srcset="" />
          ) : (
            <i class="iconfont font-48">&#xe642;</i>
          )}
          <div class="txt-c">{tips.value}</div>
        </div>
      )
    }
  },
})
