import { defineComponent } from 'vue'
import headLayerComponent from '@/components/utilComponents/headLayerComponent/headLayerComponent'
import '../style/index.scss'

export default defineComponent({
  name: 'LayerSlotComponent',
  components: {
    headLayerComponent,
  },
  emits: ['cancel', 'ok'],
  props: {
    title: {
      type: String,
      default: '提示',
    },
    cancelText: {
      type: String,
      default: '取消',
    },
    okText: {
      type: String,
      default: '确定',
    },
  },
  setup(props, { slots, emit }) {
    const footerSlot = ({
      cancelText,
      okText,
    }: {
      cancelText: string
      okText: string
    }) => {
      const handleCancel = () => {
        emit('cancel')
      }
      const handleOk = () => {
        emit('ok')
      }
      return (
        <div class="footer-layer flex-c flex-d-c">
          <a-button class="ui-base-btn1 mr24" onClick={handleCancel}>
            {cancelText}
          </a-button>
          <a-button class="ui-base-btn1" type="primary" onClick={handleOk}>
            {okText}
          </a-button>
        </div>
      )
    }

    return () => {
      const { title, cancelText, okText } = props
      return (
        <>
          <headLayerComponent class="base-color" title={title}>
            {/* <i class="iconfont font-28 base-color">&#xe661;</i> */}
          </headLayerComponent>
          <div class="txt-c">{slots.default?.()}</div>
          {slots.footer ? slots.footer() : footerSlot({ cancelText, okText })}
        </>
      )
    }
  },
})
