import { defineComponent, ref, computed } from 'vue'
import '../style/index.scss'

export default defineComponent({
  name: 'LayerComponents',
  emits: ['update:visible'],
  props: {
    closable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots, attrs, expose }) {
    const { closable } = { ...props }
    const { onCancel } = attrs
    const visible = ref(false)

    const isOpen = computed({
      get: () => visible.value,
      set: (value) => {
        visible.value = value
      },
    })

    expose({ isOpen })

    // transitionName=""
    return () => (
      <a-modal
        width="500px"
        class="ui-layer"
        v-model:visible={visible.value}
        closable={closable}
        onCancel={onCancel || (() => (isOpen.value = false))}
        {...attrs}
      >
        {slots.default && isOpen.value && slots.default()}
      </a-modal>
    )
  },
})
