import { defineComponent } from 'vue'
import './style/index.scss'

export default defineComponent({
  name: 'HeadComponent',
  props: {
    title: {
      type: String,
      required: true,
    },
    fontClass: {
      type: String,
    },
  },
  setup(props, { slots }) {
    return () => {
      const { title } = props
      // <img src="@/assets/images/login/login-icon1.png" alt="" srcset="" />
      return (
        <div class="layer-box2">
          <div class="layer-head">
            <div class="flex flex-d-c">
              {slots.default?.()}
              <span
                class={[
                  props.fontClass ? props.fontClass : '',
                  'font-18',
                  'left-line',
                ]}
              >
                {title}
              </span>
            </div>
            {slots.right?.()}
          </div>
          <div class="layer-content">{slots.form?.()}</div>
        </div>
      )
    }
  },
})
